
import { Vue } from "vue-class-component";
import {getEmployees} from "@/services/EmplyeeService";
import {getProjectByIdRequest, saveProjectContactPersonRequest} from "@/services/ObjectService";
import Swal from "sweetalert2";

export default class ObjectContactPerson extends Vue {
	showDropdown = false
	search = ""
	users: any[] = []
	user: any = {
		firstName: "",
		lastName: "",
		mobil: "",
		tel: "",
		email: ""
	}
	
	get objectId() {
		return this.$route.params.id
	}
	
	searchEmployees() {
		const data = {
			search: this.search,
			filter: {
				status: [2]
			}
		}
		getEmployees(data).then((res: any) => {
			this.users = []
			if ( res.result ) {
				res.result.map((emp: any) => {
					emp.status = parseInt(emp.status)
					this.users.push(emp)
				})
			}
		})
	}
	
	chooseEmployee(user: any) {
		this.user.firstName = user.firstName
		this.user.lastName = user.lastName
		this.user.mobil = user.mobil
		this.user.tel = user.tel
		this.user.email = user.email
		
		this.search = ""
		this.showDropdown = false
	}
	
	toggleDropdown(evt: any) {
		evt.stopPropagation()
		evt.preventDefault()
		this.showDropdown = !this.showDropdown
	}
	saveAp() {
		const data: any = this.user
		data.objectId = this.objectId
		
		saveProjectContactPersonRequest(data).then((res: any) => {
			const message = res?.result?.message ? res?.result?.message : 'Anprechpartner erfolgreich gespeichert';
			const icon = res?.result?.status == 404 ? 'error' : 'success';
			if (res.status !== 'OK') return false
			
			Swal.fire({
				icon: icon,
				title: message,
				buttonsStyling: false,
				customClass: {
					confirmButton: 'btn btn-primary'
				}
			})
		})
	}
	
	beforeMount() {
		getProjectByIdRequest(this.objectId).then((res: any) => {
			if (res.status !== 'OK') return false
			
			this.user.firstName = res.result.details.p_vorname
			this.user.lastName = res.result.details.p_name
			this.user.mobil = res.result.details.p_funk
			this.user.tel = res.result.details.p_tel
			this.user.email = res.result.details.p_email
		})
		
		this.searchEmployees()
	}
}
